<template>
  <section class="Acquisition">
    <div class="Container">
      <h3 class="Title--type-h3 Acquisition-Title">
        We work only with <span>high-quality</span> traffic
        sources which bring <span>maximum</span> benefit to our clients:
      </h3>
      <div class="Acquisition-List">
        <div class="Acquisition-Item"
             v-for="info in itemInfo"
             :key="info.id"
        >
          <div class="Acquisition-ItemNumber">{{ info.number }}</div>
          <div class="Acquisition-ItemText">{{ info.text }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Acquisition",
  data() {
    return {
      itemInfo: [
        {number: 30, text: "UA MANAGERS IN-HOUSE"},
        {number: 50, text: "MILLION INSTALLS A YEAR"},
        {number: 70, text: "COUNTRIES OF THE WORLD"},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Acquisition {
  padding: 0 0 40px;

  @media (min-width: $screen-l) {
    padding: 18px 0 90px;
  }

  &-Title {
    margin-bottom: 60px;

    @media (min-width: $screen-l) {
      margin-bottom: 100px;
      padding: 0 90px;
    }
  }

  &-List {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-Item {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.69em;
    margin-bottom: 30px;

    @media (min-width: $screen-l) {
      margin-bottom: 0;
    }
  }

  &-ItemNumber {
    margin-bottom: 30px;
    font-family: 'Lexend', sans-serif;
    font-size: 70px;
    font-weight: 700;
    color: var(--color-text-main2);

    @media (min-width: $screen-l) {
      margin-bottom: 50px;
      font-size: 100px;
    }
  }

  &-ItemText {
    font-size: 22px;
    text-transform: uppercase;
    color: var(--color-text-main2);
  }
}
</style>