<template>
  <div>
    <About :aboutInfo="aboutInfo"
           :titleMoved="titleMoved"
           :textWidth="textWidth"
    />
    <Acquisition/>
    <Partners :logos="logos" :logoBottom="logoBottom" :paddingPartners="paddingPartners"/>
    <TitleRight :title="title"/>
    <Cards :cardInfo="cardInfo"/>
    <Form/>
  </div>
</template>

<script>

import Partners from "@/components/Partners";
import Form from "@/components/Form";
import Acquisition from "@/components/Acquisition";
import About from "@/components/About";
import Cards from "@/components/Cards";
import TitleRight from "@/components/TitleRight";

export default {
  name: "UAPage",
  components: {
    TitleRight,
    Cards,
    About,
    Acquisition,
    Form,
    Partners
  },
  data() {
    return {
      title: "Why you shall choose us?",
      logos: [
        "fb.svg",
        "insta.svg",
        "googleadwords.svg",
        "twitter.svg",
        "snapchat.svg",
        "mytarget.svg",
        "unity.svg",
        "addcolony.svg",
        "vungle.svg",
        "chartboost.svg",
      ],
      logoBottom: "Partners-Logos--bottom",
      paddingPartners: "Partners--padding",
      aboutInfo: {
        title: "user acquisition",
        text: "inspired to achieve the best results and high performances"
      },
      titleMoved: "Title--moved",
      textWidth: "Text--width",
      cardInfo: [
        {
          icon: "automation.svg",
          title: "AUTOMATION",
          disableSub: true,
          text: "Technologies for optimization of traffic allow to get the maximum profit on investments.",
          disableClass: true,
        },
        {
          icon: "creative.svg",
          title: "CREATIVE",
          disableSub: true,
          text: "The creative department knows the approaches to attract and «hook» on your target audience",
          disableClass: true,
        },
        {
          icon: "anti-fraud.svg",
          title: "ANTI-FRAUD",
          disableSub: true,
          text: "The own anti-fraud system protects our clients from low-quality traffic",
          disableClass: true,
        },
        {
          icon: "quality.svg",
          title: "QUALITY AND VOLUME",
          disableSub: true,
          text: "Due to big expertise we generate large volumes of high-quality traffic in a short time.",
          disableClass: true,
        },
        {
          icon: "client.svg",
          title: "CLIENT SERVICE",
          disableSub: true,
          text: "Transparent approach in work, daily and weekly reports and a personal manager allow to provide top-level service.",
          disableClass: true,
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.Partners--padding {
  padding: 22px 0 42px;
}

</style>